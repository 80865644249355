export const {
  IS_ACTIVE,
  IS_CURRENT,
  IS_OPEN,
  IS_TRANSITIONING,
  IS_FOCUS,
  IS_HIDING,
  IS_READY,
  IS_LOADED,
  IS_ANIMATED,
  IS_DISABLED,
  NO_SCROLL,
  NO_TOUCH,
  HAS_TEXT,
  HAS_ERROR,
  DELAYS,
} = {
  IS_ACTIVE: 'active',
  IS_CURRENT: 'current',
  IS_OPEN: 'open',
  IS_TRANSITIONING: 'transitioning',
  IS_FOCUS: 'focus',
  IS_HIDING: 'hiding',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  IS_ANIMATED: 'animated',
  IS_DISABLED: 'disabled',
  NO_SCROLL: 'no-scroll',
  NO_TOUCH: 'no-touch',
  HAS_TEXT: 'has-text',
  HAS_ERROR: 'error',
  DELAYS: {
    min: 66,
    short: 100,
    medium: 200,
    long: 300,
  },
}
