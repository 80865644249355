import classNames from '../classNames'

export default async app => {
  const maskedInputs = [...document.querySelectorAll(`.${classNames.mask}`)]

  if (!maskedInputs.length) return

  const dateInputs = maskedInputs.filter(i => i.dataset.maskType === 'date')
  let moment

  if (dateInputs.length > 0) {
    const { default: m } = await import(/* webpackChunkName: "moment" */ 'moment')
    moment = m
  }

  function getOptions(mask, IMask) {
    const momentFormat = 'MM/YY'
    const placeholderChar = '_'

    return {
      phone: {
        placeholderChar,
        mask,
        lazy: true,
      },
      date: {
        placeholderChar,
        mask: Date,
        pattern: momentFormat,
        lazy: true,

        format(date) {
          return moment(date).format(momentFormat)
        },
        parse(str) {
          return moment(str, momentFormat)
        },

        blocks: {
          YY: {
            mask: IMask.MaskedRange,
            from: 20,
            to: 99,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
        },
      },
      'credit-card': {
        placeholderChar,
        mask: '0000 0000 0000 0000',
        lazy: true,
      },
    }
  }

  const init = IMask => {
    maskedInputs.forEach(input => {
      const type = input.dataset.maskType || 'phone'
      const mask = input.dataset.mask || '+{38}(000)000-00-00'

      const imask = IMask(input, getOptions(mask, IMask)[type])
      app.masks.push({
        input,
        type,
        mask: imask,
      })

      let inputValue = ''

      input.addEventListener('input', () => {
        inputValue = imask.masked.rawInputValue
      })
      input.addEventListener('focus', () => {
        imask.updateOptions({
          ...getOptions(mask, IMask)[type],
          lazy: false,
        })
      })
      input.addEventListener('blur', () => {
        imask.updateOptions({
          ...getOptions(mask, IMask)[type],
          lazy: true,
        })
        imask.value = inputValue
      })
    })
  }

  if (!('globalThis' in window))
    await import(/* webpackChunkName: "global-this-polyfill" */ 'core-js/features/global-this')

  const { default: IMask } = await import(/* webpackChunkName: "imask" */ 'imask')

  init(IMask)
}
