import regeneratorRuntime from 'regenerator-runtime'
import './public-path'
import { isModernBrowser } from './helpers'

import loadPolyfills from './polyfills/loadPolyfills'
import setHTMLClassNames from './methods/setHTMLClassNames'
import animateTitles from './methods/animateTitles'
import scrollTo from './methods/scrollTo'
import setPopups from './methods/setPopups'
import setInputMask from './methods/setInputMask'
import setTextareaHeight from './methods/setTextareaHeight'
import setScrollbar from './methods/setScrollbar'
// import setPickers from './methods/setPickers'
import addFormBlocks from './methods/addFormBlocks'
// import handleCounter from './methods/handleCounter'
import setAnimations from './methods/animations'
import handleForms from './methods/handleForms'
import setPaddings from './methods/setPaddings'
import setDynamicDate from './methods/common'

import setLazy from './components/LazyLoader/setLazy'
import Menu from './components/Menu/Menu'
import setSlider from './components/Slider/setSlider'
import setImageAnimator from './components/ImageAnimator/setImageAnimator'
import setInputs from './components/Input/setInputs'
import setSelects from './components/Select/setSelects'
import setDropdowns from './components/Dropdown/setDropdowns'
// import setRangeSliders from './components/RangeSlider/setRangeSliders'
// import initCabinetTabs from './components/Cabinet'
import setFancyLinks from './components/FancyLink/setFancyLinks'

class App {
  constructor() {
    this.methods = {}
    this.dom = {
      root: document.documentElement,
      body: document.body,
      header: document.querySelector('.header'),
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')
      ? this.dom.root.getAttribute('lang').split('-')[0]
      : 'en'
    this.state = {
      hasMenuOpen: false,
      hasPopupOpen: false,
    }

    this.menu = new Menu(this, {
      classNames: {
        btn: 'burger',
        menu: 'out__menu',
      },
    })

    this.lazyLoader = null
    this.slider = null
    this.popup = null
    this.input = null
    this.imageAnimator = null
    this.masks = []
    this.titles = []
    this.fancyLinks = []
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  initMethods() {
    this.methods = {
      setHTMLClassNames,
      setLazy,
      animateTitles,
      scrollTo,
      setSlider,
      setImageAnimator,
      setPopups,
      setInputMask,
      setInputs,
      setSelects,
      setTextareaHeight,
      setScrollbar,
      // setPickers,
      setDropdowns,
      // setRangeSliders,
      // initCabinetTabs,
      addFormBlocks,
      // handleCounter,
      setAnimations,
      setFancyLinks,
      handleForms,
      setPaddings,
      setDynamicDate,
    }

    Object.values(this.methods).forEach(fn => fn(this))
  }

  init() {
    this.initMethods()

    this.menu.init()
  }
}

const init = () => {
  const app = new App()
  app.init()
  window.app = app
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills.bind(null, init))
}
