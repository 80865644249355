import classNames from '../classNames'

export default app => {
  const onClick = ({ target }) => {
    const btn = target.closest(`.${classNames.formBlock.btn}`)
    if (!btn) return

    const block = btn.closest(`.${classNames.formBlock.block}`)
    if (!block) return

    const wrap = block.closest('.form__form')
    const newBlock = block.cloneNode(true)
    const nextSibling = block.nextElementSibling

    nextSibling ? wrap.insertBefore(newBlock, nextSibling) : wrap.appendChild(newBlock)
    btn.parentNode.removeChild(btn)

    if (app.select) app.select.update()
  }

  document.addEventListener('click', onClick)
}
